import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import InputMask from 'react-input-mask'
import { getAuth, signInAnonymously } from 'firebase/auth'
import { doc, getDoc, setDoc } from 'firebase/firestore'
import { useFormik } from 'formik'
import classNames from 'classnames'
import moment from 'moment'
import html2canvas from 'html2canvas'
import Marquee from 'react-fast-marquee'

import { useStoreActions, useStoreState } from 'stores'
import { FetchStatus, FormStatus } from 'types'

import { db } from 'firebase_config'

import './styles.scss'
import { formatCurrency, isEmpty } from 'utils'
import { Alert } from 'components'

export const Account: FC = () => {
  const navigate = useNavigate()

  const [signedIn, setSignedIn] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showClose, setShowClose] = useState<boolean>(false)
  const [showVoucher, setShowVoucher] = useState<boolean>(false)
  const [generate, setGenerate] = useState<boolean>(false)
  const [randomWord, setRandomWord] = useState<string>('M')
  const [randomColor, setRandomColor] = useState<string>('')
  const [suffleWord, setSuffleWord] = useState<string[]>([])
  const [saveSuffle, setSaveSuffle] = useState<string[]>([])
  const [info, setInfo] = useState<boolean>(false)
  const [boxAnimation, setBoxAnimation] = useState<boolean>(true)
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [redeemVoucherError, setRedeemVoucherError] = useState<boolean>(false)
  const [showRunningText, setShowRunningText] = useState<boolean>(false)
  const [playRunningText, setPlayRunningText] = useState<boolean>(false)

  const { isAuthenticated } = useStoreState(state => state.auth)
  const { loggedOut } = useStoreActions(action => action.auth)
  const { fetchProfile } = useStoreActions(action => action.profile)
  const { profile, status: profileStatus, error: profileError } = useStoreState(state => state.profile)
  const { fetchLuckyDraws } = useStoreActions(action => action.luckydraw)
  const { status, paginator } = useStoreState(state => state.luckydraw)
  const { earning } = useStoreActions(action => action.earning)
  const { status: earningStatus, error: earningError } = useStoreState(state => state.earning)
  const { fetchVoucher } = useStoreActions(action => action.voucher)
  const { voucher, status: voucherStatus, error: voucherError } = useStoreState(state => state.voucher)
  const { redeem } = useStoreActions(action => action.redeem)
  const { status: redeemStatus, error: redeemError, data } = useStoreState(state => state.redeem)

  const limit = 50

  useEffect(() => {
    if (isAuthenticated) {
      fetchProfile()
      fetchLuckyDraws({
        paginator: {
          page: 1,
          limit
        }
      })
      fetchVoucher({
        type: 'all',
        status: 'all',
        source: 'all',
        page: 1,
        limit: 1
      })
    } else {
      navigate('/')
    }
  }, [isAuthenticated, fetchLuckyDraws, fetchProfile, fetchVoucher, navigate])

  const auth = getAuth()
  const user = auth.currentUser

  useEffect(() => {
    signInAnonymously(auth)
      .then((user) => {
        console.log('USER', user)
        setSignedIn(true)
      })
      .catch((error) => {
        const errorCode = error.code
        const errorMessage = error.message
        console.error(errorCode, errorMessage)
      })
  }, [auth])

  useEffect(() => {
    setTimeout(() => {
      setShowRunningText(true)
    }, 100)
    setTimeout(() => {
      setPlayRunningText(true)
    }, 4000)
  }, [])

  const words = useMemo(() => ['M', 'A', 'C', 'S', 'H', 'Y', 'B', 'R', 'I', 'D'], [])

  const [collectWords, setCollectWords] = useState<string[]>([])

  const getCollect = useCallback(async () => {
    const docRef = doc(db, 'users', profile?.phone?.mobile)
    const docSnap = await getDoc(docRef)
    const data = docSnap.data()?.collect || []
    const chars = data
    if (chars.length > 0) {
      setSuffleWord(chars)
      setSaveSuffle(words.filter(item => !chars.includes(item)))
      setCollectWords(words.filter(item => !chars.includes(item)))
    } else {
      setSuffleWord(words)
    }
  }, [profile, words])

  useEffect(() => {
    setTimeout(() => setBoxAnimation(false), 1000)
  }, [])

  useEffect(() => {
    if (profile?.phone?.mobile && signedIn) getCollect()
  }, [profile, signedIn, getCollect])

  const shuffle = (data: string[]) => {
    for (let i = 0; i < data.length - 1; i++) {
      let j = i + Math.floor(Math.random() * (data.length - i))
      let temp = data[j]
      data[j] = data[i]
      data[i] = temp
    }
    return data
  }

  useEffect(() => {
    let interval: NodeJS.Timeout

    const chars = [
      'A', 'B', 'C', 'D',
      'E', 'F', 'G', 'H',
      'I', 'J', 'K', 'L',
      'M', 'N', 'O', 'P',
      'Q', 'R', 'S', 'T',
      'U', 'V', 'W', 'X',
      'Y', 'Z'
    ]

    const specialCharacters = [
      '!', '§', '$', '%',
      '&', '/', '(', ')',
      '=', '?', '_', '<',
      '>', '^', '°', '*',
      '#', '-', ':', ';', '~'
    ]

    const colors = [
      '#f44336', '#e91e63', '#9c27b0',
      '#673ab7', '#3f51b5', '#2196f3',
      '#03a9f4', '#00bcd4', '#009688',
      '#4caf50', '#8bc34a', '#cddc39',
      '#ffeb3b', '#ffc107', '#ff9800',
      '#ff5722', '#795548', '#9e9e9e',
      '#607d8b'
    ]

    const getRandCharacter = () => {
      const characters = chars.concat(specialCharacters)
      var randNum = Math.floor(Math.random() * characters.length)
      var picketCharacter = characters[randNum]
      var choosen = picketCharacter.toLowerCase()
      return choosen
    }

    const getRandomColor = () => {
      var randNum = Math.floor(Math.random() * colors.length)
      return colors[randNum]
    }

    if (generate) {
      interval = setInterval(() => {
        setRandomWord(getRandCharacter())
        setRandomColor(getRandomColor())
      }, 10)
    }
    return () => clearInterval(interval)
  }, [generate])

  const setCollect = useCallback(async (data: string[]) => {
    if (profile?.phone?.mobile) {
      try {
        await setDoc(doc(db, 'users', profile?.phone?.mobile), {
          collect: data
        })
      } catch (e) {
        console.error('Error adding document: ', e)
      }
    }
  }, [profile])

  useEffect(() => {
    if (profileStatus === FetchStatus.ERROR && !isEmpty(profileError)) {
      setError(profileError)
      setShowAlert(true)
    }
  }, [profileStatus, profileError])

  useEffect(() => {
    if (voucherStatus === FetchStatus.ERROR && !isEmpty(voucherError)) {
      setError(voucherError)
      setShowAlert(true)
    }
  }, [voucherStatus, voucherError])

  useEffect(() => {
    if (earningStatus === FormStatus.SUCCESS) {
      setShowModal(true)
      setGenerate(true)
    }
    if (earningStatus === FormStatus.ERROR && !isEmpty(earningError)) {
      setError(earningError)
      setShowAlert(true)
    }
  }, [earningStatus, earningError])


  const redeemVoucher = useCallback(() => {
    const id = voucher?.[0]?.id
    if (id) redeem({ products: [{ id, qty: 1 }] })
  }, [redeem, voucher])

  const reset = useCallback(() => {
    setSuffleWord(words)
    setCollectWords([])
    setRandomWord('')
    setCollect(words)
  }, [setCollect, words])

  useEffect(() => {
    if (redeemStatus === FormStatus.SUCCESS) {
      setShowAlert(false)
      setRedeemVoucherError(false)
      setShowModal(true)
      setShowVoucher(true)
      setShowClose(true)
      reset()
      fetchLuckyDraws({
        paginator: {
          page: 1,
          limit
        }
      })
    }
    if (redeemStatus === FormStatus.LOADING) {
      setShowClose(false)
    }
    if (redeemStatus === FormStatus.ERROR && !isEmpty(redeemError)) {
      setError(redeemError)
      setRedeemVoucherError(true)
      setShowAlert(true)
      setShowModal(false)
      reset()
    }
  }, [redeemStatus, redeemError, reset, fetchLuckyDraws])

  useEffect(() => {
    if (generate) {
      setTimeout(() => {
        setGenerate(false)
        setShowClose(true)
        setInfo(true)
        setTimeout(() => {
          const suffle = shuffle(suffleWord)
          setSuffleWord(suffle.slice(1))
          setRandomWord(suffle[0])
          setRandomColor('#000000')
          const collect = [...collectWords, suffle[0]]
          setSaveSuffle(collect)
          setCollect(suffle.slice(1))
          if (collect.length < words.length) {

          } else {
            setShowClose(false)
            setTimeout(() => {
              redeemVoucher()
            }, 1000)
          }
        }, 11)
      }, 1000)
    }
  }, [generate, collectWords, redeemVoucher, setCollect, suffleWord, words.length])

  useEffect(() => {
    if (showModal || showAlert) window.scrollTo({ top: 0, behavior: 'smooth' })
    if (!showAlert) setRedeemVoucherError(false)
  }, [showModal, showAlert])

  const { handleChange, handleSubmit, values } = useFormik({
    initialValues: {
      code: ''
    },
    onSubmit: values => {
      earning({
        phoneNumber: profile?.phone?.mobile,
        tracking_code: values.code
      })

      setShowVoucher(false)
      setInfo(false)
    }
  })

  const downloadImage = (blob: any, fileName: string) => {
    let link = window.document.createElement('a')
    link.style.display = 'none'
    link.download = fileName

    link.href = blob

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)

    link.remove()
  }

  const exportAsImage = async (element: any, imageFileName: string) => {
    const html = document.getElementsByTagName('html')[0]
    const body = document.getElementsByTagName('body')[0]
    let htmlWidth = html.clientWidth
    let bodyWidth = body.clientWidth
    const newWidth = element.scrollWidth - element.clientWidth
    if (newWidth > element.clientWidth) {
      htmlWidth += newWidth
      bodyWidth += newWidth
    }
    html.style.width = htmlWidth + 'px'
    body.style.width = bodyWidth + 'px'
    const canvas = await html2canvas(element, { backgroundColor: null })
    const image = canvas.toDataURL('image/png', 1.0)
    downloadImage(image, imageFileName)
  }

  const exportRef = useRef<HTMLDivElement>(null)

  return (
    <div className="account-container">
      <div className="back">
        <div
          className="button"
          onClick={() => loggedOut(() => user?.delete().catch(error => console.log(error)))}
        >
          <img
            className="icon"
            src={require('assets/images/icons/back.svg').default}
            alt="icon"
          />
        </div>
      </div>
      <div className="logo">
        <img
          className="macs"
          src={require('assets/images/logo/macs.png')}
          alt="macs"
        />
        <img
          className="macs-hybrid-gel"
          src={require('assets/images/logo/macs-hybrid-gel.png')}
          alt="macs-hybrid-gel"
        />
        {/* <div className="hybrid-gel">HYBRID GEL</div> */}
      </div>
      {/* <div>
        <div className={classNames('running-info', { show: showRunningText })}>
          <Marquee
            play={playRunningText}
            speed={70}
            pauseOnClick
          >
            <div className="running-text">
              <span>
                Undian <strong>GRAND PRIZE MACS HYBRID GEL</strong> tahap I berakhir tanggal <strong>30 September 2024</strong>.
              </span>
              <span>
                Seluruh nomor undian yang sudah di submit s.d 30 September 2024 akan dilakukan Pengundian nya di YouTube Macsworld Official pada tanggal <strong>10 Oktober 2024</strong>.
                Nomor-nomor tersebut secara otomatis akan ter-reset di system pada tanggal 01 September 2024.
              </span>
              <span>
                Ikuti kembali Undian <strong>GRAND PRIZE MACS HYBRID GEL</strong> tahap II dengan periode 01 Oktober 2024 s.d 31 Juli 2025.
              </span>
            </div>
          </Marquee>
        </div>
      </div> */}
      <img
        className="pen"
        src={require('assets/images/banner/pen-horizontal.png')}
        alt="pen"
      />
      <div className="card">
        <div className="profile">
          <div>
            <img
              className="icon"
              src={require('assets/images/icons/profile.svg').default}
              alt="icon"
            />
            &nbsp;
            {profile?.name ?
              <div className="name">
                {profile?.name}
              </div>
              :
              <div
                className="skeleton"
                style={{ width: '12rem' }}
              />
            }
          </div>
          <div>
            <img
              className="icon"
              src={require('assets/images/icons/phone.svg').default}
              alt="icon"
            />
            &nbsp;
            {profile?.phone?.mobile ?
              <InputMask
                mask="+62 999-9999-9999-99"
                maskPlaceholder={null}
                value={`+${profile?.phone?.mobile}`}
                beforeMaskedStateChange={({ nextState }) => {
                  let { value } = nextState
                  if (value.endsWith('-')) value = value.slice(0, -1)
                  return {
                    ...nextState,
                    value
                  }
                }}
                autoCorrect="off"
                spellCheck="false"
                autoComplete="off"
              />
              :
              <div
                className="skeleton"
                style={{ width: '10rem', animationDelay: '.1s' }}
              />
            }
          </div>
        </div>
        <div
          className="lucky-number"
          onClick={() => navigate('/lucky-number')}
        >
          <img
            className="icon"
            src={require('assets/images/icons/voucher.svg').default}
            alt="icon"
          />
          <div className="label">
            <div>Lucky</div>
            <div>Number</div>
          </div>
          {status === FetchStatus.LOADED && paginator.total_items > 0 &&
            <div className="badge">
              {paginator.total_items}
            </div>
          }
        </div>
      </div>
      <div className="words">
        {['M', 'A', 'C', 'S'].map((word, index) =>
          <div
            className={classNames('box', { active: !boxAnimation && collectWords.includes(word) })}
            style={{ transform: `scale(${boxAnimation ? 0 : .9})`, animation: boxAnimation ? `show-box .5s ${(index + 1) * .1}s ease forwards` : 'unset' }}
            key={index}
          >
            <div className="border">
              <div className="inner">
                {word}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="words">
        {['H', 'Y', 'B', 'R', 'I', 'D'].map((word, index) =>
          <div
            className={classNames('box', { active: !boxAnimation && collectWords.includes(word) })}
            style={{ transform: `scale(${boxAnimation ? 0 : .9})`, animation: boxAnimation ? `show-box .5s ${(index + 1) * .1}s ease forwards` : 'unset' }}
            key={index}
          >
            <div className="border">
              <div className="inner">
                {word}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="info">
        Kumpulkan huruf "<strong style={{ fontFamily: 'ethnocentric' }}>MACS HYBRID</strong>" & dapatkan <strong>E-VOUCHER SHOPEE</strong> untuk berbelanja di  <strong>MACS WORLD OFFICIAL SHOP</strong>
      </div>
      <form onSubmit={handleSubmit} className="form">
        <div className="input-code">
          <InputMask
            placeholder="Masukkan Kode Unik"
            mask="****-****-****-****-**"
            maskPlaceholder={null}
            value={values.code}
            onChange={e => handleChange('code')(e.target.value.toUpperCase())}
            beforeMaskedStateChange={({ nextState }) => {
              let { value } = nextState
              if (value.endsWith('-')) value = value.slice(0, -1)
              return {
                ...nextState,
                value
              }
            }}
            autoCorrect="off"
            spellCheck="false"
            autoComplete="off"
          />
          <button
            type="submit"
            disabled={!profile?.id || !values.code || earningStatus === FormStatus.LOADING}
          >
            &nbsp;
            {earningStatus === FormStatus.LOADING ?
              <img
                className="loader"
                src={require('assets/images/icons/loader.svg').default}
                alt="loader"
              />
              :
              <span>SUBMIT</span>
            }
            &nbsp;
          </button>
        </div>
      </form>
      {redeemStatus !== FormStatus.ERROR &&
        <div className={classNames('generate', { show: showModal })}>
          <div className="content" ref={exportRef}>
            <div className="box">
              <div
                className={classNames('close', { show: showClose })}
                onClick={() => {
                  setShowClose(false)
                  setShowModal(false)
                  handleChange('code')('')
                  setTimeout(() => !showVoucher && setCollectWords(saveSuffle), 200)
                }}
                data-html2canvas-ignore
              >
                <img
                  className="icon"
                  src={require('assets/images/icons/close.svg').default}
                  alt="icon"
                />
              </div>
              {showVoucher ?
                <div className="voucher">
                  <div className="text" data-html2canvas-ignore>
                    Selamat Anda mendapatkan <strong>E-Voucher Shopee</strong>,
                    silakan <strong><i>Download</i></strong> atau <strong><i>Screenshoot</i></strong> halaman voucher ini.
                  </div>
                  <div className="voucher-card">
                    <div className="inner">
                      <img
                        className="image"
                        src={require('assets/images/logo/shopee.png')}
                        alt="shopee-logo"
                      />
                      <div className="amount">{formatCurrency(Number(data?.amount))}</div>
                      <div className="code">
                        <div className="label">KODE VOUCHER</div>
                        <div className="number">{data?.serial_no}</div>
                      </div>
                    </div>
                    <div className="expired">
                      Berlaku hingga : {moment(data?.expired_at).format('DD MMM YYYY')}
                    </div>
                  </div>
                  <button
                    className="download-button"
                    onClick={() => exportAsImage(exportRef.current, `voucher_shopee_macs_hybrid_${data?.serial_no}.png`)}
                    data-html2canvas-ignore
                  >
                    Download Voucher
                  </button>
                </div>
                :
                <div>
                  <div
                    className="random-word"
                    style={{ color: randomColor }}
                  >
                    {randomWord}
                  </div>
                  <div className={classNames('info', { show: info })}>
                    {(words.length - 1) - collectWords.length > 0 ?
                      <div>
                        <div className="collect">
                          {['M', 'A', 'C', 'S', 'H', 'Y', 'B', 'R', 'I', 'D'].map((word, index) =>
                            <div
                              className={classNames('word', { space: word === 'S', active: saveSuffle.includes(word) })}
                              key={index}
                            >
                              {word}
                            </div>
                          )}
                        </div>
                        <div>
                          Terima kasih telah mengikuti program ini, kumpulkan <strong>{(words.length - 1) - collectWords.length}</strong> huruf lagi untuk mendapatkan <strong>E-Voucher Shopee</strong>.
                        </div>
                      </div>
                      :
                      <div>
                        <div>
                          Selamat Anda mendapatkan <strong>E-Voucher Shopee</strong>.
                        </div>
                        <div className="loading-voucher">
                          <img
                            className="loader"
                            src={require('assets/images/icons/loader.svg').default}
                            alt="loader"
                          />
                          <div>Memuat voucher...</div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      }
      <div className="powered">
        <div
          className="button"
          onClick={() => window.open('https://loyal.id')}
        >
          <div className="label">Powered by</div>
          <img
            className="logo-loyalid"
            src={require('assets/images/logo/loyalid-white.svg').default}
            alt="logo-loyalid"
          />
        </div>
      </div>
      <Alert
        text={redeemStatus !== FormStatus.LOADING ? error : ''}
        show={showAlert}
        onClose={() => {
          setShowAlert(false)
          setRedeemVoucherError(false)
          setTimeout(() => setError(''), 1000)
        }}
        whatsappButton={error?.toLowerCase()?.includes('whatsapp') || redeemVoucherError}
        retryButton={redeemVoucherError && !error.includes('insufficient')}
        retryAction={redeemVoucher}
        retryLoading={redeemStatus === FormStatus.LOADING}
      />
    </div>
  )
}
