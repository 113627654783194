export enum FetchStatus {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  ERROR = 'ERROR',
  REFRESHING = 'REFRESHING',
  REFRESH_ERROR = 'REFRESH_ERROR',
  FETCHING_MORE = 'FETCHING_MORE',
  FETCHING_MORE_ERROR = 'FETCHING_MORE_ERROR'
}

export enum AuthStatus {
  LOADING = 'LOADING',
  AUTHENTICATED = 'AUTHENTICATED',
  UNAUTHENTICATED = 'UNAUTHENTICATED'
}

export enum FormStatus {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR'
}
